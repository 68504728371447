import type { AnalyticsConfig } from "@website/types";
import { getOrientation, getViewport } from "./utils";
import { ChangeEvent, GALLERY_CHANGE_EVENT } from "./gallery";
import { netidEventsArray, StatusEvent } from "./netid";
import {
  NEWSLETTER_SUBSCRIPTION,
  NEWSLETTER_TOPIC_CLICKED,
  NewsletterClickedEvent,
  NewsletterSubscriptionEvent,
} from "./socialwidget";
import { LOADED_EVENT } from "./bookmarklist";
import { BOOKMARK_TOGGLE_EVENT, BookmarkButton } from "./bookmarkbutton";

export const GTM_INIT_EVENT = "gtmInitialized";

interface EventData {
  event: string;
  [key: string]: unknown;
}

/**
 * This web component, GoogleTagManager, is a custom HTML element designed
 * to integrate Google Tag Manager (GTM) into a web page.
 * @see  https://support.google.com/tagmanager/answer/14847097
 */
export class GoogleTagManager extends HTMLElement {
  get containerId(): string {
    return this.getAttribute("container-id") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    try {
      this.#initialize(this.#defaultPageConfig());

      //Add eventlisteners for virtual pageview in galleries
      const galleries = document.querySelectorAll("ws-gallery");
      galleries.forEach((gallery) => {
        gallery.addEventListener(
          GALLERY_CHANGE_EVENT,
          this.#handleVirtualPageview,
        );
      });

      //Add eventlistener for NetID
      const netid = document.querySelector("ws-netid");
      netidEventsArray.forEach((entry) => {
        netid?.addEventListener(entry, this.#handleNetidEvent);
      });

      //Add eventlisteners for Newsletterform
      const socialWidgets = document.querySelectorAll("ws-socialwidget");

      socialWidgets.forEach((widget) => {
        widget.addEventListener(
          NEWSLETTER_TOPIC_CLICKED,
          this.#handleNewsletterTopicClicked,
        );

        widget.addEventListener(
          NEWSLETTER_SUBSCRIPTION,
          this.#handleNewsletterSubscribed,
        );
      });
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing Google Tag Manager configuration:", error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
    this.#bindTeasers();
    this.#bindBookmarkButtons();
  }

  #executeScripts() {
    const script = document.createElement("script");
    script.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.containerId}');`;
    this.shadowRoot!.appendChild(script);
  }

  #handleNetidEvent = (e: Event) => {
    const event = e as StatusEvent;
    const details = event.detail
      ? { [event.detail.eventAction]: event.detail.eventLabel }
      : null;
    window.dataLayer.push({
      event: event.type,
      ...details,
    });
  };

  #handleNewsletterTopicClicked = (e: Event) => {
    const event = e as NewsletterClickedEvent;
    window.dataLayer.push({
      event: event.type,
      ...event.detail,
    });
  };

  #handleNewsletterSubscribed = (e: Event) => {
    const event = e as NewsletterSubscriptionEvent;
    window.dataLayer.push({
      event: event.type,
      ...event.detail,
    });
  };

  #handleVirtualPageview = (e: Event) => {
    const event = e as ChangeEvent;
    const { galleryHeadline, itemNumber, itemUrl, totalItems } = event.detail;

    const currentConfig = this.#defaultPageConfig();
    const galleryConfig = {
      ...currentConfig,
      content: {
        ...currentConfig.content,
        virt_path_url: itemUrl,
      },
      gallery: {
        current_picture: itemNumber,
        galleryHeadline: galleryHeadline,
        number_of_pictures: totalItems,
      },
      event: "virt_path",
    };
    window.dataLayer.push(galleryConfig);
  };

  async #initialize(config: AnalyticsConfig) {
    // Enhance the configuration with clientside data
    config.content.molten_bundle_site_type = getViewport();
    config.tech.device_orientation = getOrientation();

    // Enhance the configuration with user data
    const user = document.querySelector("ws-user");
    if (user) {
      await window.customElements.whenDefined("ws-user");
      const status = await user.getStatus();
      config.login.distribution_channel = status.type ?? "not_set";
      config.login.hashed_user_id = status.hashedUserId ?? "not_set";
      config.login.product_user_status = status.trackingStatus ?? "not_set";
      config.login.registration_status = status.registrationStatus ?? "not_set";
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(config);

    this.#executeScripts();
    this.dispatchEvent(new CustomEvent(GTM_INIT_EVENT));
  }

  #defaultPageConfig(): AnalyticsConfig {
    const configScript = this.querySelector(`script[type="application/json"]`);

    if (!configScript) {
      console.warn("Google Tag Manager configuration script not found.");
      return this.#defaultConfig(); // Return a basic default config
    }

    try {
      const config = JSON.parse(configScript.textContent ?? "{}");
      return this.#validateConfig(config) ? config : this.#defaultConfig();
    } catch (error) {
      console.error("Error parsing Google Tag Manager configuration:", error);
      return this.#defaultConfig(); // Fallback to default config
    }
  }

  // Define a basic fallback configuration
  #defaultConfig() {
    return {
      content: {},
      tech: {},
      login: {},
    } as AnalyticsConfig;
  }

  // Validation method for required structure or fields
  #validateConfig(config: AnalyticsConfig): boolean {
    // Simple check for example purposes; expand as needed
    return (
      config &&
      typeof config === "object" &&
      "content" in config &&
      "tech" in config
    );
  }

  #bindTeasers() {
    // Select all elements that match the attribute "data-trace-id='teaser'"
    const teasers = document.querySelectorAll<HTMLElement>(
      "[data-trace-id='teaser']",
    );

    teasers.forEach((teaser) => {
      // Extract dataset attributes with default empty strings for safety
      const {
        brandIdentifier = "",
        contentId = "",
        headline = "",
        paidCategory = "",
        teaserType = "",
        type = "",
      } = teaser?.dataset as DOMStringMap;
      const teaserLink = teaser.querySelector("a");

      if (!teaserLink) return;

      // Add click event listener for each teaser
      teaserLink.addEventListener("click", () => {
        // Push the teaser click event to the data layer
        this.#pushToDataLayer({
          event: "teaser_clicked",
          content: {
            brand: brandIdentifier,
            headline,
            id: contentId,
            paidCategory,
            teaserType,
            type,
          },
        });
      });
    });
  }

  #bindBookmarkButtons() {
    const bookmarkPaywallTriggers = document.querySelectorAll<HTMLElement>(
      "ws-paywallcontrol .feature-bar__button:has(.icon-bookmark)",
    );
    bookmarkPaywallTriggers.forEach((trigger) => {
      trigger.addEventListener("click", this.#handleForbiddenBookmarkAddEvents);
    });

    const bindSingleButton = (button: BookmarkButton) => {
      button.addEventListener(
        BOOKMARK_TOGGLE_EVENT,
        this.#handleBookmarkButtonEvents,
      );
    };

    const bookmarkButtons = document.querySelectorAll("ws-bookmarkbutton");
    bookmarkButtons.forEach(bindSingleButton);

    const bookmarkList = document.querySelector("ws-bookmarklist");
    if (bookmarkList) {
      // Wait for the bookmark list to be loaded. If the event was fired before
      // the bookmarks must have been included in bookmarkButtons.
      // @ts-expect-error TS2769: No overload matches this call.
      bookmarkList.addEventListener(
        LOADED_EVENT,
        (event: CustomEvent): void => {
          const newBookmarkButtons = (
            event.target as HTMLElement
          ).querySelectorAll("ws-bookmarkbutton");
          newBookmarkButtons.forEach(bindSingleButton);
        },
      );
    }
  }

  #handleForbiddenBookmarkAddEvents = (event: Event) => {
    const bookmarkPaywallTrigger = (event.target as HTMLElement).closest(
      "ws-paywallcontrol button:has(.icon-bookmark)",
    );
    if (bookmarkPaywallTrigger) {
      this.#pushToDataLayer({
        event: "bookmark_clicked",
        bookmark_state: "forbidden",
      });
    }
  };

  #handleBookmarkButtonEvents = (event: Event) => {
    const bookmarkbutton = (event.target as HTMLElement).closest(
      "ws-bookmarkbutton",
    );
    if (bookmarkbutton) {
      this.#pushToDataLayer({
        event: "bookmark_clicked",
        bookmark_state: bookmarkbutton.active ? "added" : "removed",
      });
    }
  };

  // Private method to push data to the data layer
  #pushToDataLayer(data: EventData): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  disconnectedCallback() {
    const galleries = document.querySelectorAll("ws-gallery");
    galleries.forEach((gallery) => {
      gallery.removeEventListener(
        GALLERY_CHANGE_EVENT,
        this.#handleVirtualPageview,
      );
    });

    const bookmarkPaywallTriggers = document.querySelectorAll<HTMLElement>(
      "ws-paywallcontrol .feature-bar__button:has(.icon-bookmark)",
    );
    bookmarkPaywallTriggers.forEach((trigger) => {
      trigger.removeEventListener(
        "click",
        this.#handleForbiddenBookmarkAddEvents,
      );
    });

    const bookmarkButtons = document.querySelectorAll("ws-bookmarkbutton");
    bookmarkButtons.forEach((button) => {
      button.removeEventListener(
        BOOKMARK_TOGGLE_EVENT,
        this.#handleBookmarkButtonEvents,
      );
    });
  }
}

customElements.get("ws-gtm") ??
  customElements.define("ws-gtm", GoogleTagManager);

declare global {
  interface HTMLElementTagNameMap {
    "ws-gtm": GoogleTagManager;
  }
  interface Window {
    dataLayer: any[];
  }
}
