export const LOADED_EVENT = "loaded";

export default class Bookmarklist extends HTMLElement {
  #bookmarkRoute = "/p-api/lists/bookmarks/items";

  static get observedAttributes(): string[] {
    return ["disabled"];
  }

  get paidCategory(): string | null {
    return this.getAttribute("paid-category");
  }

  get errorPage(): string | null {
    return this.getAttribute("error-page");
  }

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  constructor() {
    super();

    // Add styles to the shadow DOM
    const style = document.createElement("style");
    style.innerHTML = `
      ws-bookmarklist {
        display: block;
      }
    `;
    document.head.appendChild(style); // Directly append to shadowRoot
  }

  connectedCallback(): void {
    this.#initialize();
  }

  #initialize(): void {
    if (!this.disabled) {
      this.#load();
    }
  }

  async #load(): Promise<void> {
    this.dispatchEvent(new CustomEvent("loading"));
    const contentIds = await this.#getContentIds();
    if (contentIds) {
      const result = await this.#fetchBookmarkListFragment(contentIds).catch(
        (error: Error) => {
          this.#handleError(error.message);

          /* tag error explicit to execute empty state block */
          return null;
        },
      );
      // Handle success
      if (result) {
        const children = this.#replace(result);
        this.dispatchEvent(
          new CustomEvent(LOADED_EVENT, { detail: { children } }),
        );
      } else {
        /* handle logged in empty state */
        this.#handleEmptyState();
      }
    } else {
      /* handle not logged in empty state*/
      this.#handleEmptyState();
    }
  }

  async #getContentIds(): Promise<string | undefined> {
    const requestUrl = this.paidCategory
      ? `${this.#bookmarkRoute}?paidCategory=${this.paidCategory}`
      : this.#bookmarkRoute;
    const result = await this.#fetchBookmarkListItems(requestUrl).catch(
      (error: Error) => {
        this.#handleError(error.message);
      },
    );
    if (!result) {
      return;
    }
    if (result.items?.length > 0) {
      const contentIds = result.items.map(
        (item: { contentId: string }) => item.contentId,
      );
      return contentIds.join();
    } else {
      this.#handleError("Keine Einträge vorhanden.");
    }
  }

  async #fetchBookmarkListItems(apiUrl: string): Promise<any> {
    const response = await fetch(apiUrl, {
      headers: { accept: "application/json" },
    });
    if (!response.ok) {
      console.error("Error loading bookmark list items");
      return "";
    }
    return await response.json();
  }

  async #fetchBookmarkListFragment(contentIds: string): Promise<string> {
    const requestUrl = `/?partial=search&has_bookmarklist=1&contentId=${contentIds}`;
    const response = await fetch(requestUrl);
    if (!response.ok) {
      console.error("Error loading bookmark list fragments");
      return "Keine Einträge vorhanden.";
    }
    return await response.text();
  }

  #handleError(message: string): void {
    if (this.errorPage) {
      location.href = this.errorPage;
    } else {
      const htmlString = `<p>${message}</p>`;
      const children = this.#replace(htmlString);
      this.dispatchEvent(
        new CustomEvent(LOADED_EVENT, { detail: { children } }),
      );
    }
  }

  #replace(html: string): Element[] {
    const dom = document.createRange().createContextualFragment(html);
    const children = Array.from(dom.children);
    this.innerHTML = ""; // Clear existing content in the shadow DOM
    this.appendChild(dom);
    return children;
  }

  #handleEmptyState() {
    /* get data attributes */
    const emptyStateTitle =
      this.getAttribute("data-title")?.trim() || "Noch keine gemerkten Inhalte";
    const emptyStateSubTitle =
      this.getAttribute("data-subtitle")?.trim() ||
      "Wie funktioniert die Merkliste";
    const emptyStateLink =
      this.getAttribute("data-article-id")?.trim() || "40903824";
    const emptyStateNewTab =
      this.getAttribute("data-new-tab")?.toLowerCase() === "true" || false;

    /* get current protocol & host to build accurate link with data attributes */
    const protocol = window.location.protocol;
    const host = window.location.host;
    const createdLink = `${protocol}//${host}/${emptyStateLink}`;

    const htmlFrame = () => {
      const imageBlock = '<div class="bookmarklist-empty-state__image"></div>';
      const titleBlock = `<div class="typo-info-head"><p>${emptyStateTitle}</p></div>`;
      const subTitleBlock = `<div class="typo-body-default-underlined"><a href="${createdLink}" target="${emptyStateNewTab ? "_blank" : "_self"}">${emptyStateSubTitle}</a></div>`;

      /* build html frame */
      return `<div class="bookmarklist-empty-state">${imageBlock}<div class="bookmarklist-empty-state__content">${titleBlock}${subTitleBlock}</div></div>`;
    };

    /* add html block to DOM */
    const emptyStateFrame = htmlFrame();
    this.#replace(emptyStateFrame);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null,
  ): void {
    if (name === "disabled" && newValue === null) {
      this.#initialize();
    }
  }
}

customElements.get("ws-bookmarklist") ??
  customElements.define("ws-bookmarklist", Bookmarklist);

declare global {
  interface HTMLElementTagNameMap {
    "ws-bookmarklist": Bookmarklist;
  }
}
