export type ShortTextGalleryChangeEvent = CustomEvent<{
  count: number;
  itemNumber?: string;
  adTagIds: string[];
}>;

export const SHORT_TEXT_GALLERY_CHANGE_EVENT = "change";
const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      display: block;
      position: relative;
    }

    :host([hidden]) {
      display: none;
    }

    #scroll-container {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-width: none;
      gap: .5rem;
    }

    @media (min-width: 768px) {
      #scroll-container {
        gap: 1rem;
      }
    }

    #scroll-container::-webkit-scrollbar {
      display: none;
    }

    #items::slotted(*) {
      flex: none;
      scroll-snap-align: start;
      width: 100%;
    }

    #item-counter {
      font-size: .875rem;
      padding: 0.375rem 0.75rem;
    }

    #current-item {
      font-weight: bolder;
    }

    #base {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 0.75rem;
      margin-right: 1.5rem;
    }

    @media (min-width: 768px) {
      #base {
        margin-right: 6.25rem;
      }
    }

    @media (min-width: 1024px) {
      #base {
        margin-right: 4.375rem;
        margin-bottom: 1rem;
      }
    }

    #navigation {
      display: none;
    }

    @media (min-width: 768px) {
      #navigation {
        z-index: 2;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 2.75rem);
        left: -1.5rem;
      }
    }

    @media (min-width: 1024px) {
      #navigation {
        width: calc(100% - 0.875rem);
        left: -1.5rem;
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      :host {
        scroll-behavior: smooth;
      }
    }
  </style>
  <div id="base">
    <slot name="headline"></slot>
    <div id="item-counter"><span id="current-item"></span>/<span id="total-items"></span></div>
  </div>
  <slot name="gradient" id="scroll-gradient"></slot>
  <div id="scroll-container">
    <slot id="items"></slot>
    <nav id="navigation">
        <slot name="previous"></slot>
        <slot name="next"></slot>
    </nav>
  </div>
  <div id="dots" data-current-slide="1"><slot name="dots"></slot></div>
`;

export class TextGallery extends HTMLElement {
  #currentItem: HTMLElement;
  // eslint-disable-next-line no-unused-private-class-members
  #itemCounter: HTMLElement;
  #slots: Record<string, HTMLSlotElement> = {};
  #items: HTMLElement[] = [];
  #currentIndex: number;
  #nextButton: HTMLButtonElement;
  #prevButton: HTMLButtonElement;
  #scrollContainer: HTMLElement;
  #intersectionCounter = 0;
  #itemsObserver: IntersectionObserver;
  #totalItems: HTMLElement;
  #dots: HTMLElement;
  #scrollGradient: HTMLElement;
  #onNextClick: () => void;
  #onPrevClick: () => void;
  #onKeyDown: (event: KeyboardEvent) => void;

  get totalItems() {
    return this.getAttribute("total-items") ?? "0";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
    this.#currentItem = this.shadowRoot!.getElementById("current-item")!;
    this.#currentIndex = 0;
    this.#slots.items = this.shadowRoot!.getElementById(
      "items",
    )! as HTMLSlotElement;
    this.#slots.next = this.shadowRoot!.querySelector(
      `slot[name="next"]`,
    )! as HTMLSlotElement;
    this.#slots.previous = this.shadowRoot!.querySelector(
      `slot[name="previous"]`,
    )! as HTMLSlotElement;
    this.#slots.dots = this.shadowRoot!.querySelector(
      `slot[name="dots"]`,
    )! as HTMLSlotElement;
    this.#nextButton =
      this.#slots.next.assignedElements()[0] as HTMLButtonElement;
    this.#prevButton =
      this.#slots.previous.assignedElements()[0] as HTMLButtonElement;
    this.#dots = this.#slots.dots.assignedElements()[0] as HTMLElement;
    this.#scrollContainer =
      this.shadowRoot!.getElementById("scroll-container")!;
    this.#itemCounter = this.shadowRoot!.getElementById("item-counter")!;
    this.#totalItems = this.shadowRoot!.getElementById("total-items")!;
    this.#scrollGradient = this.shadowRoot!.getElementById("scroll-gradient")!;
    this.#onNextClick = this.#scrollNext.bind(this);
    this.#onPrevClick = this.#scrollPrevious.bind(this);
    this.#onKeyDown = this.#handleKeyboardInteraction.bind(this);
    this.#itemsObserver = new IntersectionObserver(
      this.#handleIntersection.bind(this),
      {
        root: this.#scrollContainer,
        rootMargin: "0px",
        threshold: 0.5,
      },
    );
  }

  connectedCallback() {
    this.#totalItems.textContent = this.totalItems;
    this.#items = this.#slots.items!.assignedElements() as HTMLElement[];
    this.#items.forEach((item, index) => {
      if (!item.dataset.itemNumber) {
        item.dataset.itemNumber = (index + 1).toString();
      }
      this.#itemsObserver.observe(item);
    });
    this.#nextButton.addEventListener("click", this.#onNextClick);
    this.#prevButton.addEventListener("click", this.#onPrevClick);
    window.addEventListener("keydown", this.#onKeyDown);
    this.#handleClickDots();
    this.#handleScrollingInsideItem();
  }

  #handleKeyboardInteraction(event: KeyboardEvent) {
    switch (event.key.toLowerCase()) {
      case "r":
        this.#handleRKey();
        break;
      case "arrowright":
        this.#scrollNext();
        break;
      case "arrowleft":
        this.#scrollPrevious();
        break;
    }
  }

  #handleIntersection(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      if (entry.isIntersecting && entry.target instanceof HTMLElement) {
        const itemNumber = entry.target.dataset.itemNumber;
        this.#updateItemCounter(itemNumber);
        this.#createActiveDotOnSlide(itemNumber);
        this.#updateButtonsVisibility(itemNumber);

        if (itemNumber !== this.totalItems) {
          this.#scrollGradient.style.display = "block";
        } else {
          this.#scrollGradient.style.display = "none";
        }

        if (this.#intersectionCounter > 0) {
          this.#dispatchChangeEvent(this.#intersectionCounter, itemNumber);
        }
        this.#intersectionCounter++;

        break;
      }
    }
  }

  #handleScrollingInsideItem() {
    this.#items.forEach((item) => {
      const yScrollContainer = item.querySelector(
        ".short-text-gallery__item-content",
      ) as HTMLElement | null;
      setTimeout(() => {
        if (yScrollContainer) {
          const itemHeight = item.offsetHeight;
          const yScrollContainerHeight = yScrollContainer.offsetHeight;
          const { paddingTop, paddingBottom } = getComputedStyle(item);
          const contentHeight =
            item.clientHeight -
            parseFloat(paddingTop) -
            parseFloat(paddingBottom);
          const vpLarge = 1024;
          const threshold = window.innerWidth >= vpLarge ? 400 : 450;
          if (
            contentHeight === yScrollContainerHeight &&
            itemHeight >= threshold
          ) {
            yScrollContainer.classList.add("has-overflow");
            item.classList.add("has-bottom-gradient");
            yScrollContainer.addEventListener("scroll", () => {
              const isAtBottom =
                Math.ceil(
                  yScrollContainer.scrollTop + yScrollContainer.clientHeight,
                ) >= yScrollContainer.scrollHeight;
              item.classList.toggle("reached-end", isAtBottom);
            });
          }
        }
      }, 150);
    });
  }

  #dispatchChangeEvent = (count: number, itemNumber?: string) => {
    const event: ShortTextGalleryChangeEvent = new CustomEvent(
      SHORT_TEXT_GALLERY_CHANGE_EVENT,
      {
        detail: {
          count,
          itemNumber,
          adTagIds: [
            "wallpaper_1",
            "superbanner_1",
            "skyscraper_1",
            "rectangle_1",
            "mobile_1",
          ],
        },
      },
    );

    this.dispatchEvent(event);
  };

  #scrollNext() {
    if (this.#currentIndex < this.#items.length - 1) this.#currentIndex += 1;
    this.#scrollToItem(this.#currentIndex);
  }

  #scrollPrevious() {
    if (this.#currentIndex > 0) this.#currentIndex -= 1;
    this.#scrollToItem(this.#currentIndex);
  }

  #scrollToItem(index?: number) {
    /* Check if scroll container exists */
    if (
      !this.#scrollContainer ||
      index === undefined ||
      index < 0 ||
      index >= this.#items.length
    )
      return;
    const targetItem = this.#items[index]!;
    this.#currentIndex = index;

    /* Smooth scroll to the target position */
    targetItem.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  #handleRKey() {
    this.#scrollContainer.scrollTo({
      behavior: "smooth",
      left: 0,
    });
  }

  #setActiveDot(index?: number) {
    /* Check if dots container exists and has list items */
    if (!this.#dots || typeof index !== "number") return;
    const dotsListItems = Array.from(this.#dots.querySelectorAll("li"));

    /* Ensure the item number is within bounds */
    if (dotsListItems.length && index >= 0 && index < dotsListItems.length) {
      /* Remove active class from all list items */
      dotsListItems.forEach((listItem) => listItem.classList.remove("active"));

      /* Add active class to the selected dot */
      const activeListItem = dotsListItems[index];
      if (activeListItem) {
        activeListItem.classList.add("active");
      }
    }

    /* static numbers */
    const maxDotsCenter = 6;
    /*
      max dots are always double the maxDotsCenter + 1 for current dot
      (current shall be centered always)
    */
    const maxDots = maxDotsCenter * 2 + 1;

    /* handle dots display if length is greater than maxDots (13) */
    if (Number(this.totalItems) > maxDots) {
      const increaseItemNumber = (index || 0) + 1;
      this.#handleDotsDisplay(
        dotsListItems,
        increaseItemNumber,
        maxDots,
        maxDotsCenter,
      );
    }
  }

  #handleDotsDisplay(
    dotsListItems: HTMLElement[],
    increaseItemNumber: number,
    maxDots: number,
    maxDotsCenter: number,
  ) {
    let itemsToShow: HTMLElement[];
    const removeLayoutClasses = (
      itemList: HTMLElement[],
      htmlClass: string,
    ) => {
      itemList.forEach((item) => {
        item.classList.remove(htmlClass);
      });
    };

    removeLayoutClasses(dotsListItems, "small");
    removeLayoutClasses(dotsListItems, "smaller");

    if (increaseItemNumber > maxDotsCenter) {
      /* Handle overflow if current item number is greater than the center of visible dots */
      itemsToShow = dotsListItems.slice(
        increaseItemNumber - 1 - maxDotsCenter,
        increaseItemNumber + maxDotsCenter,
      );
    } else {
      /* If not in overflow, show the first maxDots items */
      itemsToShow = dotsListItems.slice(0, maxDots);
    }

    const firstItem = itemsToShow.slice(0, 1);
    const secondFirstItem = itemsToShow.slice(1, 2);
    const lastItem = itemsToShow.slice(-1);
    const secondLastItem = itemsToShow.slice(-2, -1);

    /* Show only the selected items */
    itemsToShow.forEach((item) => {
      item.style.display = "block";
    });

    /* Hide the rest of the items in overflow */
    const itemsToHide = dotsListItems.filter(
      (item) => !itemsToShow.includes(item),
    );
    itemsToHide.forEach((item) => {
      item.style.display = "none";
    });

    const addLayoutClasses = (itemList: HTMLElement[], htmlClass: string) => {
      itemList.forEach((item) => {
        item.classList.add(htmlClass);
      });
    };

    if (dotsListItems.length - maxDotsCenter >= increaseItemNumber) {
      addLayoutClasses(secondLastItem, "small");
      addLayoutClasses(lastItem, "smaller");
    }

    if (increaseItemNumber > maxDotsCenter) {
      addLayoutClasses(secondFirstItem, "small");
      addLayoutClasses(firstItem, "smaller");
    }
  }

  #createActiveDotOnSlide(itemNumber?: string) {
    if (itemNumber) {
      /* Convert item number to zero-based index */
      const index = parseInt(itemNumber, 10) - 1;

      /* Check if data is valid before proceeding */
      if (index >= 0 && this.#dots && this.#scrollContainer) {
        this.#setActiveDot(index);
      }
    }
  }

  #handleClickDots() {
    if (!this.#dots) return;
    this.#dots.querySelectorAll("li button").forEach((button, index) => {
      const handler = this.#createDotClickHandler(index);
      button.addEventListener("click", handler);
    });
  }

  #updateButtonsVisibility(itemNumber?: string) {
    const isAtEnd = itemNumber === this.totalItems.toString();
    const isAtStart = itemNumber === "1";
    if (this.#prevButton) {
      isAtStart
        ? this.#prevButton.classList.add("hidden")
        : this.#prevButton.classList.remove("hidden");
    }
    if (this.#nextButton) {
      isAtEnd
        ? this.#nextButton.classList.add("hidden")
        : this.#nextButton.classList.remove("hidden");
    }
  }

  #updateItemCounter(itemNumber?: string) {
    if (itemNumber) {
      this.#currentItem.textContent = itemNumber;
    }
  }

  disconnectedCallback() {
    this.#nextButton.removeEventListener("click", this.#onNextClick);
    this.#prevButton.removeEventListener("click", this.#onPrevClick);
    window.removeEventListener("keydown", this.#onKeyDown);
    if (this.#dots) {
      this.#dots.querySelectorAll("li button").forEach((button, index) => {
        button.removeEventListener("click", this.#createDotClickHandler(index));
      });
    }
    this.#items.forEach((item) => {
      const yScrollContainer = item.querySelector(
        ".short-text-gallery__item-content",
      ) as HTMLElement | null;
      if (yScrollContainer) {
        yScrollContainer.removeEventListener(
          "scroll",
          this.#handleScrollingInsideItem,
        );
      }
    });
    this.#itemsObserver.disconnect();
  }

  #createDotClickHandler(index: number) {
    return () => {
      this.#setActiveDot(index);
      this.#scrollToItem(index);
    };
  }
}

customElements.get("ws-short-text-gallery") ??
  customElements.define("ws-short-text-gallery", TextGallery);

declare global {
  interface HTMLElementTagNameMap {
    "ws-short-text-gallery": TextGallery;
  }
}
