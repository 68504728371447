import { BookmarkButton } from "./bookmarkbutton";

/**
 * The purpose of this web component is to store and provide data for user-specific
 * functionalities such as bookmark lists, etc.
 */
export class BookmarkManager extends HTMLElement {
  #bookmarkRoute = "/p-api/lists/bookmarks/items";
  #contentIds: string[] = [];

  constructor() {
    super();
  }

  async #loadBookmarks() {
    this.dispatchEvent(new CustomEvent("loading"));

    const data = await this.#fetchBookmarkListItems(this.#bookmarkRoute).catch(
      (error: Error) => {
        console.error(error.message);
      },
    );

    if (data && data.items) {
      this.#contentIds = data.items.map(
        (item: { contentId: string }) => item.contentId,
      );
    }
  }

  async #fetchBookmarkListItems(apiUrl: string): Promise<any> {
    const response = await fetch(apiUrl, {
      headers: { accept: "application/json" },
    });
    if (!response?.ok) {
      throw new Error("An error occurred while fetching the bookmark list.");
    }
    return await response.json();
  }

  #setBookmarkButtonStates = () => {
    const bookmarkButtons = document.querySelectorAll("ws-bookmarkbutton");
    bookmarkButtons.forEach((button: BookmarkButton) => {
      button.active = this.#contentIds.includes(button.contentId);
    });
  };

  connectedCallback() {
    this.#loadBookmarks().then(this.#setBookmarkButtonStates);
  }

  getContentIds() {
    return this.#contentIds;
  }
}

customElements.get("ws-bookmarkmanager") ??
  customElements.define("ws-bookmarkmanager", BookmarkManager);

declare global {
  interface HTMLElementTagNameMap {
    "ws-bookmarkmanager": BookmarkManager;
  }
}
