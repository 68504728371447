const template = document.createElement("template");

export const NEWSLETTER_TOPIC_CHANGE = "change";
export const NEWSLETTER_TOPIC_CLICKED = "nl_clicked";
export const NEWSLETTER_SUBSCRIPTION = "nl_confirmed";

export type NewsletterClickedEvent = CustomEvent<{
  nl_id: string;
}>;
export type NewsletterSubscriptionEvent = CustomEvent<{
  nl_id: string[];
}>;

template.innerHTML = `
  <style>

    :host {
      display: block;
    }

    :host #content[hidden] {
      /* Wenn die "display" Eigenschaft überschrieben wird, muss "display: none"
        wiedergestellt werden, für den Fall das dass Element ein "hidden" Attribut hat. */
      display: none;
    }

  </style>
  <slot name="content" id="content"></slot>
  <slot name="consentHeadline" id="consentHeadline"></slot>
  <slot name="consentText" id="consentText"></slot>
  <slot name="button"></slot>
`;

export class SocialWidget extends HTMLElement {
  #slots: Record<string, HTMLSlotElement> = {};
  #content: Element;
  #button: HTMLButtonElement;
  #isLoaded = false;

  get noTracking() {
    return this.hasAttribute("data-no-tracking");
  }

  constructor() {
    super();

    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    this.#slots.button = this.shadowRoot!.querySelector(
      `slot[name="button"]`,
    )! as HTMLSlotElement;
    this.#slots.content = this.shadowRoot!.querySelector(
      "#content",
    )! as HTMLSlotElement;

    this.#button =
      this.#slots.button.assignedElements()[0]! as HTMLButtonElement;
    this.#content = this.#slots.content.assignedElements()[0] as Element;
  }

  async connectedCallback() {
    const consent = await this.#getConsent();

    if (this.noTracking) {
      this.#button!.addEventListener("click", () => this.embedWidget());
      return;
    } else if (consent) {
      this.embedWidget();
    } else {
      this.#button!.addEventListener("click", () => this.embedWidget());
    }

    const checkFunction = () => {
      if (typeof window.__tcfapi === "function") {
        window.__tcfapi(
          "addEventListener",
          2,
          (tcdata: { eventStatus: any; listenerId?: any }, success: string) => {
            if (success) {
              this.updateConsentForWidget();
              if (tcdata.eventStatus === "useractioncomplete") {
                // call code when user has made an action
                this.updateConsentForWidget();
              } else if (tcdata.eventStatus === "tcloaded") {
                // remove event listener when consent string has loaded
                window.__tcfapi("removeEventListener", 2, tcdata.listenerId);
              } else if (tcdata.eventStatus === "cmpuishown") {
                // call code when cmp message is shown
              }
            }
          },
        );

        clearInterval(intervalId); // Stop checking for the function
      }
    };

    const intervalId = setInterval(checkFunction, 500); // Check every 0.5 seconds for the function
  }

  async updateConsentForWidget() {
    if (this.#isLoaded) {
      return;
    }
    const consent = await this.#getConsent();
    if (consent) {
      this.embedWidget();
    }
  }

  async #getConsent(): Promise<boolean> {
    const sourcepoint = document.querySelector(
      "ws-sourcepoint",
    ) as HTMLElement | null;

    if (sourcepoint) {
      // Ensure the 'ws-sourcepoint' element is defined
      await window.customElements.whenDefined("ws-sourcepoint");
      return new Promise((resolve) => {
        const smPurposeIds = [
          "63d7a897cc43ae06156ab273",
          "662779476b5999067b9e054b",
          "5ffec086984dc02862cbbed1",
        ];
        // We have different ids on different environments:
        //  662779476b5999067b9e054b => next.dev. and *.stern.de
        //  5ffec086984dc02862cbbed1 => *.stern.de (Apps)
        //  63d7a897cc43ae06156ab273 => localhost

        // Wrap the callback-based API in a Promise
        try {
          window.__tcfapi("getCustomVendorConsents", 2, (data: any) => {
            if (data && data.consentedPurposes) {
              const hasConsent = data.consentedPurposes.some((purpose: any) => {
                return smPurposeIds.includes(purpose._id);
              });
              resolve(hasConsent);
            } else {
              // If data is not as expected, resolve to false or handle accordingly
              resolve(false);
            }
          });
        } catch (error) {
          // In case of any errors, reject the promise
          console.error(error);
          resolve(false);
        }
      });
    }

    // Fallback if sourcepoint is not present
    return false;
  }

  embedWidget = () => {
    // Create new element from content slot
    const documentFragment = document
      .createRange()
      .createContextualFragment(
        `<div class="widget-element">${this.#content.textContent || ""}</div>`,
      ); // https://davidwalsh.name/convert-html-stings-dom-nodes
    const widgetElement = documentFragment.querySelector(".widget-element")!;
    this.replaceWith(documentFragment);

    //Add events for newsletterform
    const newsletterForm = widgetElement.querySelector(
      ".js-nl-form-tracking",
    ) as unknown as HTMLFormElement;

    if (newsletterForm) {
      const newsletterTopicsList: string[] = [];

      const newsletterTopics = newsletterForm?.querySelectorAll(
        "[id*=newsletter_id]",
      ) as unknown as HTMLInputElement[];

      newsletterTopics?.forEach((topic) => {
        topic.addEventListener(NEWSLETTER_TOPIC_CHANGE, () => {
          const pattern = /\d+/;
          const topicId = topic?.id?.match(pattern)![0];
          if (topic.checked) {
            const event: NewsletterClickedEvent = new CustomEvent(
              NEWSLETTER_TOPIC_CLICKED,
              {
                detail: {
                  nl_id: topicId,
                },
              },
            );
            this.dispatchEvent(event);
            newsletterTopicsList.push(topicId);
          } else {
            const topicIndex = newsletterTopicsList.indexOf(topicId);
            newsletterTopicsList.splice(topicIndex, 1);
          }
        });
      });

      newsletterForm.onsubmit = () => {
        const event: NewsletterSubscriptionEvent = new CustomEvent(
          NEWSLETTER_SUBSCRIPTION,
          {
            detail: {
              nl_id: newsletterTopicsList,
            },
          },
        );
        this.dispatchEvent(event);
      };
    }

    // Remove ws-socialwidget
    this.remove();
    this.#isLoaded = true;
  };

  disconnectedCallback() {
    this.#button.removeEventListener("click", () => this.embedWidget());
  }
}

"customElements" in window &&
  customElements.get("ws-socialwidget") === undefined &&
  customElements.define("ws-socialwidget", SocialWidget);

declare global {
  interface HTMLElementTagNameMap {
    "ws-socialwidget": SocialWidget;
  }
}
