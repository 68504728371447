import { InteractionObserver } from "./utils";

export type ChangeEvent = CustomEvent<{
  adTagIds?: string[];
  count: number;
  galleryHeadline?: string;
  itemNumber?: string;
  itemUrl?: string;
  totalItems?: string;
}>;

export const GALLERY_CHANGE_EVENT = "change";
export const OPEN_PAYWALL_DIALOG = "open-paywall-dialog";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      display: block;
      position: relative;
    }

    :host([hidden]) {
      display: none;
    }

    #scroll-container {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-width: none;
    }

    #scroll-container::-webkit-scrollbar {
      display: none;
    }

    #items::slotted(*) {
      flex: none;
      scroll-snap-align: start;
      width: 100%;
    }

    #item-counter {
      backdrop-filter: blur(6px);
      background-color: var(--ws-gallery-item-counter-background-color);
      border-radius: 4px;
      color: var(--ws-gallery-item-counter-color);
      display: var(--ws-gallery-item-counter-display, block);
      padding: var(--ws-gallery-item-counter-padding);
      position: absolute;
      right: var(--ws-gallery-space-horizontal);
      top: var(--ws-gallery-space-vertical);
    }

    #item-counter[hidden] {
      display: none;
    }

    #current-item {
      color: var(--ws-gallery-current-item-color);
      font-weight: var(--ws-gallery-current-item-font-weight);
    }

    #base {
      box-sizing: border-box;
      min-height: var(--ws-gallery-base-min-height);
      padding: var(--ws-gallery-base-padding);
    }

    #navigation {
      bottom: var(--ws-gallery-space-vertical);
      display: flex;
      gap: 12px;
      position: absolute;
      right: var(--ws-gallery-space-horizontal);
    }

    @media (prefers-reduced-motion: no-preference) {
      :host {
        scroll-behavior: smooth;
      }
    }
  </style>
  <div id="scroll-container"><slot id="items"></slot></div>
  <div id="item-counter"><span id="current-item"></span>/<span id="total-items"></span></div>
  <div id="base">
    <slot name="headline"></slot>
  </div>
  <nav id="navigation">
    <slot name="previous"></slot>
    <slot name="next"></slot>
  </nav>
`;

export class Gallery extends HTMLElement {
  #currentItem: HTMLElement;
  #currentItemNodeIndex = 0;
  #galleryHeadline: HTMLElement;
  #interactionObserver?: InteractionObserver;
  #intersectionCounter = 0;
  #itemCounter: HTMLElement;
  #items: HTMLElement[];
  #itemsObserver: IntersectionObserver;
  #mutationObserver: MutationObserver;
  #nextButton: HTMLAnchorElement;
  #prevButton: HTMLAnchorElement;
  #resizeObserver: ResizeObserver;
  #scrollContainer: HTMLElement;
  #slots: Record<string, HTMLSlotElement> = {};
  #totalItems: HTMLElement;
  #touchStartX = 0;
  #touchStartY = 0;

  get loading() {
    return this.getAttribute("loading") ?? "eager";
  }

  get totalItems() {
    return this.getAttribute("total-items") ?? "0";
  }

  isRestricted() {
    return this.hasAttribute("data-is-restricted");
  }

  constructor() {
    super();

    // Setup Shadow DDOM
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    // Setup HTML elements
    this.#currentItem = this.shadowRoot!.getElementById("current-item")!;
    this.#itemCounter = this.shadowRoot!.getElementById("item-counter")!;
    this.#slots.items = this.shadowRoot!.getElementById(
      "items",
    )! as HTMLSlotElement;
    this.#items = this.#slots.items.assignedElements()! as HTMLElement[];
    this.#slots.next = this.shadowRoot!.querySelector(
      `slot[name="next"]`,
    )! as HTMLSlotElement;
    this.#nextButton =
      this.#slots.next.assignedElements()[0]! as HTMLAnchorElement;
    this.#slots.previous = this.shadowRoot!.querySelector(
      `slot[name="previous"]`,
    )! as HTMLSlotElement;
    this.#prevButton =
      this.#slots.previous.assignedElements()[0]! as HTMLAnchorElement;
    this.#scrollContainer =
      this.shadowRoot!.getElementById("scroll-container")!;
    this.#totalItems = this.shadowRoot!.getElementById("total-items")!;
    this.#slots.galleryHeadline = this.shadowRoot!.querySelector(
      `slot[name="headline"]`,
    )! as HTMLSlotElement;
    this.#galleryHeadline =
      this.#slots.galleryHeadline.assignedElements()[0] as HTMLElement;

    // Setup IntersectionObserver
    this.#itemsObserver = new IntersectionObserver(this.#handleIntersection, {
      root: this.#scrollContainer,
      rootMargin: "0px",
      threshold: 0.6,
    });

    // Setup InteractionObserver
    if (this.loading === "interaction") {
      this.#interactionObserver = new InteractionObserver(
        this.#handleInteraction,
      );
    }

    // Setup MutationObserver
    this.#mutationObserver = new MutationObserver(this.#handleMutation);

    //Setup ResizeObserver
    this.#resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Do not use scrollBy, it has issues with zoom on Safari 18.0.1
        this.#scrollContainer.scroll({
          behavior: "instant", // Prevent scrolling issues in combination with css scroll-snap-type
          left: entry.contentRect.width * this.#currentItemNodeIndex,
        });
      }
    });
  }

  connectedCallback() {
    // Setup item counter
    this.#totalItems.textContent = this.totalItems;

    // Setup IntersectionObserver for each item
    // Button visibility and item counter are updated on the initial intersection callback.
    for (const item of this.#items) {
      this.#itemsObserver.observe(item);
    }

    this.#resizeObserver.observe(this.#scrollContainer);

    // Setup MutationObserver to add dynamically loaded items to the IntersectionObserver
    this.#mutationObserver.observe(this, { childList: true });

    // Setup event listeners
    if (this.#nextButton && !this.isRestricted()) {
      this.#nextButton.addEventListener("click", this.#scrollNext);
    }
    if (this.#prevButton && !this.isRestricted()) {
      this.#prevButton.addEventListener("click", this.#scrollPrevious);
    }

    if (this.isRestricted()) {
      this.#scrollContainer.addEventListener("wheel", this.#handleWheel);
      this.#scrollContainer.addEventListener(
        "touchstart",
        this.#handleTouchStart,
      );
      this.#scrollContainer.addEventListener(
        "touchmove",
        this.#handleTouchMove,
      );
    }

    this.loading === "interaction"
      ? this.#interactionObserver?.observe(this)
      : this.#handleInteraction();
  }

  getViewportElement() {
    return this.#scrollContainer;
  }

  #handleInteraction = () => {
    this.#interactionObserver?.disconnect();

    // Activate all items
    this.#items.forEach((element) => {
      element.removeAttribute("disabled");
    });
  };

  #handleWheel = (event: WheelEvent) => {
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      event.preventDefault();
      this.#dispatchOpenPaywallDialogEvent();
    }
  };

  #handleTouchStart = (event: TouchEvent) => {
    const touch = event.touches[0];
    this.#touchStartX = touch!.clientX;
    this.#touchStartY = touch!.clientY;
  };

  #handleTouchMove = (event: TouchEvent) => {
    const touch = event.touches[0];
    const touchEndX = touch!.clientX;
    const touchEndY = touch!.clientY;
    const deltaX = touchEndX - this.#touchStartX;
    const deltaY = touchEndY - this.#touchStartY;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      event.preventDefault();
      this.#dispatchOpenPaywallDialogEvent();
    }
  };

  // Triggers also on intersection of first gallery item (not only when navigating through the gallery)
  #handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const galleryHeadline = this.#galleryHeadline?.innerText.trim();

    for (const entry of entries) {
      if (entry.isIntersecting && entry.target instanceof HTMLElement) {
        //Set #currentItemNodeIndex to pin position in scroll container
        this.#currentItemNodeIndex = Array.prototype.indexOf.call(
          this.#slots.items!.assignedElements(),
          entry.target,
        );

        const itemNumber = entry.target.dataset.itemNumber;

        this.#updateItemCounter(itemNumber);

        // Re-enable component
        const itemUrl = entry.target.dataset.itemUrl;
        const totalItems = this.totalItems;

        this.#updateBrowserUrl(itemUrl);
        this.#updateButtonsVisibility();

        if (this.#intersectionCounter > 0) {
          this.#dispatchChangeEvent(
            this.#intersectionCounter,
            galleryHeadline,
            itemNumber,
            itemUrl,
            totalItems,
          );
        }
        this.#intersectionCounter++;
      }
    }
  };

  #handleMutation = (mutationList: MutationRecord[]) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              this.#itemsObserver.observe(node);
            }
          });
        }
        if (mutation.removedNodes.length > 0) {
          mutation.removedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              this.#itemsObserver.unobserve(node);
            }
          });
        }
      }
    }
  };

  #dispatchChangeEvent = (
    count: number,
    galleryHeadline?: string,
    itemNumber?: string,
    itemUrl?: string,
    totalItems?: string,
  ) => {
    const event: ChangeEvent = new CustomEvent(GALLERY_CHANGE_EVENT, {
      detail: {
        count,
        galleryHeadline,
        itemNumber,
        itemUrl,
        totalItems,
        adTagIds: ["wallpaper_1", "superbanner_1", "skyscraper_1"],
      },
    });

    this.dispatchEvent(event);
  };

  #dispatchOpenPaywallDialogEvent = () => {
    document.dispatchEvent(new CustomEvent(OPEN_PAYWALL_DIALOG));
  };

  #scrollNext = (event: MouseEvent) => {
    event.preventDefault();
    // Do not use scrollBy, it has issues with zoom on Safari 18.0.1
    this.#scrollContainer.scroll({
      behavior: "instant", // Prevent scrolling issues in combination with css scroll-snap-type
      left:
        this.#scrollContainer.scrollLeft + this.#scrollContainer.clientWidth,
    });
  };

  #scrollPrevious = (event: MouseEvent) => {
    event.preventDefault();
    // Do not use scrollBy, it has issues with zoom on Safari 18.0.1
    this.#scrollContainer.scroll({
      behavior: "instant", // Prevent scrolling issues in combination with css scroll-snap-type
      left:
        this.#scrollContainer.scrollLeft - this.#scrollContainer.clientWidth,
    });
  };

  #updateButtonsVisibility() {
    if (this.isRestricted()) {
      const totalItemsCount = parseInt(this.totalItems, 10);
      const currentItemNumber = Number(this.#currentItem.textContent);

      if (totalItemsCount <= 1) {
        this.#nextButton.inert = true;
        this.#prevButton.inert = true;
      } else if (totalItemsCount >= 1) {
        this.#nextButton.inert = false;

        this.#prevButton.inert = currentItemNumber === 1;

        if (currentItemNumber === totalItemsCount) {
          this.#nextButton.inert = true;
        }
      }
    } else {
      this.#nextButton!.inert =
        Math.ceil(
          this.#scrollContainer.scrollLeft + this.#scrollContainer.clientWidth,
        ) >= this.#scrollContainer.scrollWidth; // Prevent rounding issues in zoomed browsers
      this.#prevButton!.inert = this.#scrollContainer.scrollLeft === 0;
    }
  }

  #updateItemCounter(itemNumber?: string) {
    if (itemNumber) {
      this.#currentItem.textContent = itemNumber;
      this.#itemCounter.hidden = false;
    } else {
      this.#itemCounter.hidden = true;
    }
  }

  #updateBrowserUrl(itemUrl?: string) {
    if (!itemUrl) return;
    const currentUrl = new URL(window.location.href);
    const updatedUrl = new URL(itemUrl);
    currentUrl.searchParams.forEach((value, key) => {
      updatedUrl.searchParams.set(key, value);
    });
    try {
      window.history.replaceState(null, "", updatedUrl.toString());
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  }

  disconnectedCallback() {
    if (this.#nextButton && !this.isRestricted()) {
      this.#nextButton.removeEventListener("click", this.#scrollNext);
    }
    if (this.#prevButton && !this.isRestricted()) {
      this.#prevButton.removeEventListener("click", this.#scrollPrevious);
    }
    this.#itemsObserver.disconnect();
    this.#interactionObserver?.disconnect();
    this.#mutationObserver.disconnect();

    if (this.isRestricted()) {
      this.#scrollContainer.removeEventListener("wheel", this.#handleWheel);
      this.#scrollContainer.removeEventListener(
        "touchstart",
        this.#handleTouchStart,
      );
      this.#scrollContainer.removeEventListener(
        "touchmove",
        this.#handleTouchMove,
      );
    }
  }
}

"customElements" in window &&
  customElements.get("ws-gallery") === undefined &&
  customElements.define("ws-gallery", Gallery);

declare global {
  interface HTMLElementTagNameMap {
    "ws-gallery": Gallery;
  }
}
